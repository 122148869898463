<template>
	<div class="page_content">
		<div class="page_detail">

			<a-divider orientation="left">订单配置</a-divider>
			<div>
				<div class="sign">
					<span>经销商签名：</span>
					<span>
						<a-switch default-checked v-model="config_data.distribute" @change="configChange()" />
					</span>
				</div>
				<div class="sign">
					<span>发送签名模板消息：</span>
					<span>
						<a-switch default-checked v-model="config_data.send_confirm" @change="configChange()" />
					</span>
				</div>
				<div class="sign">
					<span>是否录入批次：</span>
					<span>
						<a-switch default-checked v-model="config_data.is_enter" @change="configChange()" />
					</span>
				</div>
				<div class="sign">
					<span>司机自提权限：</span>
					<span>
						<a-switch default-checked v-model="config_data.is_pick_up" @change="configChange()" />
					</span>
				</div>
				<div class="sign">
					<span>送达距离：</span>
					<span>
						<a-input v-model="config_data.arrive_distance" @change="configChange()" placeholder="请输入距离"
							min="0" type='number' style="width:120px" /> 米
						<!-- <a-switch default-checked/> -->
					</span>
				</div>
				
				
				
			</div>
			<!-- <a-divider orientation="left">从批次录入切换至溯源码录入</a-divider>
			<div>
				<div class="sign" style="padding-bottom: 20px;">
					<span>仓库配置：</span>
					<a-radio-group @change='change_tracing_auth'  v-model="tracing_auth.type">
						<a-radio value="all">
							全部切换
						</a-radio>
						<a-radio value="part">
							部分切换
						</a-radio>
					</a-radio-group>
				</div>
				<div class="sign" style="padding-bottom: 20px;" v-if="tracing_auth.type=='part'">
					<span>切换仓库：</span>
					<span class="manage_main" v-for="(item,index) in tracing_auth.data" :key="index">
						<span class="manage_user">
							{{ item.storage_name }}
							<img src="../../../assets/img/icon_delete.png" @click="ware_delete(index)" />
						</span>
					</span>
					<a @click="add_fact" style="display:inline-block;">选择仓库</a>
				</div>
			</div> -->
			<a-divider orientation="left">托盘配置</a-divider>
			<div class="sign" style="padding-bottom: 20px;">
				<span>损坏托盘信息接收人：</span>
				<span class="manage_main" v-for="(item,index) in tray_send.user" :key="index">
					<span class="manage_user">
						{{ item.username }}
						<img src="../../../assets/img/icon_delete.png" @click="manage_delete(index)" />
					</span>
				</span>
				<a @click="add_manage" style="display:inline-block;">选择人员</a>
			</div>

			<a-tabs type="card" @change="tab_change" class='tab_info'>
				<a-tab-pane :key="0" tab="司机详情展示内容"></a-tab-pane>
				<a-tab-pane :key="1" tab="经销商详情展示内容"></a-tab-pane>
				<a-tab-pane :key="2" tab="分销商详情展示内容"></a-tab-pane>
				<a-tab-pane :key="3" tab="仓管详情展示内容"></a-tab-pane>
			</a-tabs>





			<!-- <a-divider orientation="left">详情展示内容</a-divider> -->
			<div>
				<p>
					<a-button :type="primary==1?'primary':''" @click='change_pri(1)'>桶装</a-button>
					<a-button :type="primary==2?'primary':''" @click='change_pri(2)' class='right_btn'>瓶装</a-button>
					<a-button :type="primary==3?'primary':''" @click='change_pri(3)'
						class='right_btn'>{{tab_index == 1 || tab_index == 2 ? '订货单' : '空桶'}}</a-button>
					<a-button :type="primary==4?'primary':''" @click='change_pri(4)' class='right_btn'
						v-if="tab_index != 2">托盘</a-button>
				</p>
				<div class="info_box">
					<div>
						<!-- <p class="checked_btn"  v-show="primary==1">
							<a-checkbox v-model:checked="allchecked" @change='changeStatus(1)'>全选</a-checkbox>
						</p>
						<p class="checked_btn" v-show="primary==2">
							<a-checkbox v-model:checked="allchecked2" @change='changeStatus(1)'>全选</a-checkbox>
						</p> -->
						<div>
							<div v-if="show_array.seller_data">
								<div class="info_title">经销商信息：</div>
								<div>
									<Project :project_data="show_array.seller_data" :type="'seller'"
										@changeStatus="changeStatus"></Project>
								</div>
							</div>
							<div v-if="show_array.ware_data">
								<div class="info_title">仓库信息：</div>
								<div>
									<Project :project_data="show_array.ware_data" :type="'pallet'"
										@changeStatus="changeStatus"></Project>
								</div>
							</div>
							<div v-if="show_array.order_data">
								<div class="info_title">订单信息：</div>
								<div>
									<Project :project_data="show_array.order_data" :type="'order'"
										@changeStatus="changeStatus"></Project>
								</div>
							</div>
							<div v-if="show_array.send_data">
								<div class="info_title">配送信息：</div>
								<div>
									<Project :project_data="show_array.send_data" :type="'send'"
										@changeStatus="changeStatus"></Project>
								</div>
							</div>
							<div v-if="show_array.goods_data">
								<!-- <div class="info_title">产品信息：</div>
                                <div>
                                    <Project :project_data="show_array.goods_data.show"></Project>
                                </div> -->
								<div class="info_title" style="display:flex">
									<div>{{show_array.goods_data.show.name}}：</div>
									<div>
										<a-switch default-checked v-model="show_array.goods_data.show.show_goods"
											@change="productChange()" />
									</div>
								</div>
								<div v-if="show_array.goods_data.show.show_goods">
									<a @click="add_news()">
										<a-icon type="plus-circle" />
										<span style="padding-left: 5px">添加字段</span>
									</a>
									<a-input type="hidden" name="new_combination"
										v-decorator="['new_combination', new_combination]" />
									<div>
										<ul>
											<li v-for="(item, index) in new_combination" :key="index">
												<div class="com_box">
													<div class="com_info" style="width:120px;">
														<span class="info_color">排序：</span>
														<a-input v-model="item.sort" placeholder="请输入序号" min="0"
															type='number' style="width:70px"
															@change="input_change(index,item.sort)"
															:disabled="index > 0 ? false : true" />
														<!-- <span>{{ item.sort }}</span> -->
													</div>
													<div class="com_info" style="width:180px">
														<span class="info_color">组合名称：</span>
														<span>{{ item.name }}</span>
													</div>
													<div class="com_info">
														<span class="info_color">规则：</span>
														<span>{{item.rule}}</span>
													</div>
													<div class="com_info" v-if="index > 0">
														<a @click="del_new(index)">删除</a>
													</div>
												</div>
											</li>
										</ul>
									</div>
								</div>
							</div>

							<div v-if="show_array.empty_data && primary == 1">
								<div class="info_title">空桶信息：</div>
								<div>
									<Project :project_data="show_array.empty_data" :type="'empty'"
										@changeStatus="changeStatus"></Project>
								</div>
							</div>
						</div>
					</div>
					<!-- <div class="phone">
						<div class="show_box">
							<Show :new_combination='new_combination' :new_info.sync='new_info' :sign_status="sign_status"></Show>
						</div>
					</div> -->
				</div>

			</div>

			<div class="box_form box_form_btns">
				<a-button type="defalut" @click="$router.back()">返回</a-button>
			</div>
		</div>
		<addRule @handleSubmit="combination_submit" :form_data="form_data_combination"
			:visible.sync="combination_visible" :width="'700px'" :combina="select_list"
			:combina_methord="combina_methord" :form_data_all="form_data_all" @get_form_data_all="get_form_data_all"
			@del_data="del_data" @cancle_rule="cancle_rule"></addRule>
		<SelectUser @submit="submit_manage" :visible.sync="visible_manage" select_type="checkbox"></SelectUser>
		<WarehouseSelectCheck ref="WarehouseSelectCheck" @submit="submit_ware" :visible.sync="show_ware"></WarehouseSelectCheck>
	</div>
</template>

<script>
	import addRule from "../components/add_rule.vue";
	import LookImages from "@/components/LookImages";
	import WarehouseSelectCheck from "@/components/WarehouseSelectCheck";

	import Show from "../components/show.vue";
	import Project from "../components/project.vue";
	import SelectUser from "@/components/SelectUser";
	import {
		Modal
	} from 'ant-design-vue'

	import {
		getWareSelect,
		get_template_setting,
		save_template_setting,
		save_product_rule,
		save_config,
		saveTraySend,
		setTracingAuth
	} from '@/api/push.js'
	export default {
		components: {
			LookImages,
			WarehouseSelectCheck,
			addRule,
			Show,
			Project,
			SelectUser,
			Modal
			// RightOutlined
		},
		data() {
			return {
				sign_status: {
					distribute: true
				},
				shipment_confirm: {},
				driver_data: [], //司机信息
				show_array: [], //展示信息
				tab_index: 0, //顶部切换栏
				primary: 1, //1桶装 2瓶装 3空桶切换
				new_combination: [], //添加字段信息
				add_combination: [], //添加字段信息
				combination_visible: false, //是否添加字段              
				select_list: [], //商品下拉数据
				config_data: {}, //配置
				new_info: {}, //右侧展示
				allchecked: false,
				allchecked2: false,
				visible: false,

				form_data_all: [], //lili
				form_data_combination: {
					name: "",
					sort: ""
				},
				combina_methord: [{
						key: "",
						value: "请选择"
					},
					{
						key: "+",
						value: "加",
					},
					{
						key: "-",
						value: "减",
					},
					{
						key: "*",
						value: "乘",
					},
					{
						key: "/",
						value: "除"
					}
				],
				tray_send: {
					"user": [],
					"depart": []
				},
				tracing_auth: {},
				visible_manage: false,
				show_ware: false,
			}
		},
		created() {
			this.get_info()
		},
		methods: {
			change_tracing_auth(){
				if(this.tracing_auth.type=='all'){
					this.set_Tracing_Auth()
				}else{
					this.add_fact()
				}
			},
			//删除人员选择后的数据
			ware_delete(index) {
				let that = this
				Modal.confirm({
					title: '温馨提示',
					content: '确定要删除该工厂吗？',
					okText: '确定',
					cancelText: '取消',
					onOk() {
						that.tracing_auth.data.splice(index, 1);
						that.set_Tracing_Auth( that.tracing_auth.data)
					}
				});
			
			},
			submit_ware(data) {
				let arr = []

				data.data.forEach(item => {
                    arr.push({storage_id:item.storage_id,storage_name:item.storage_name})
				})

				this.set_Tracing_Auth(arr)
			},
			set_Tracing_Auth(arr) {
				setTracingAuth({
					data: {
						type: this.tracing_auth.type,
						data: this.tracing_auth.type == 'arr' ? '' : arr
					},
					info: true
				}).then(res => {
					this.get_info()
				})
			},
			add_fact() {
                if(this.tracing_auth.data){
                    this.$refs.WarehouseSelectCheck.select_user_list = [...this.tracing_auth.data]
                    this.$refs.WarehouseSelectCheck.selectedRowKeys = this.tracing_auth.data.map(item=>{
                        return item.storage_id 
                    })
                }
               
				this.show_ware = true
			},
			//添加规则	
			get_form_data_all() {
				let rule = {
					operator: '',
					field: [],
					join_operator: ''
				}
				this.form_data_all.push(rule)
			},
			//tab栏切换
			tab_change(key) {
				this.tab_index = key;
				this.primary = 1;
				// this.show_array = [];
				this.get_info();
			},
			//按钮切换
			change_pri(type) {
				this.primary = type
				// this.show_array = [];
				this.get_info();
			},
			//产品明细展示
			productChange(e) {
				this.save_show();
			},
			changeStatus(type) {
				this.save_show()
			},
			save_show() {
				save_template_setting({
					data: {
						type: this.tab_index == 0 ? 'driver' : (this.tab_index == 1 ? 'seller' : (this.tab_index ==
							2 ? 'distribute' : 'manage')),
						flag: this.primary,
						data: this.show_array
					}
				}).then(res => {
					this.get_info();
				})
			},

			submit(e) {
				this.openid = e.values.openid
				this.uid = this.select_user.id
				this.visible = false;
				this.changeStatus('user')
			},
			//获取详情
			get_info() {
				get_template_setting({
					data: {
						type: this.tab_index == 0 ? 'driver' : (this.tab_index == 1 ? 'seller' : (this.tab_index ==
							2 ? 'distribute' : 'manage')),
						flag: this.primary
					}
				}).then(res => {
					this.show_array = res.data.list
					this.new_combination = res.data.rule.show
					this.add_combination = res.data.rule.add
					this.tracing_auth = res.data.tracing_auth
					this.config_data = res.data.config
					if (res.data.tray_send.user.length > 0) {
						this.tray_send = res.data.tray_send
					}
				})
			},
			//添加字段
			add_news(e) {
				this.combination_visible = true
				this.get_select()
				this.get_form_data_all()
			},
			//字段保存
			combination_submit(e) {
				let new_array = e
				this.add_combination.push(new_array)
				this.save_field();
				this.cancle_rule();
			},
			//获取商品下拉
			get_select() {
				this.select_list = []
				let obj = this.show_array.goods_select
				for (let key in obj) {
					this.select_list.push({
						key: key,
						value: obj[key]
					})
				}
			},
			del_data(e) {
				this.form_data_all.splice(e, 1)
			},
			cancle_rule() {
				this.combination_visible = false
				this.form_data_all = []
				this.form_data_combination = {}
			},
			save_field() {
				save_product_rule({
					data: {
						type: this.tab_index == 0 ? 'driver' : (this.tab_index == 1 ? 'seller' : (this.tab_index ==
							2 ? 'distribute' : 'manage')),
						flag: this.primary,
						field_rule: this.add_combination
					},
					Toast: true
				}).then(res => {
					this.get_info();
				})
			},
			//删除字段
			del_new(index) {
				this.add_combination.splice(index, 1);
				this.save_field()
			},
			input_change(index, e) {
				if (e !== '') {
					this.add_combination[index].sort = e
					this.save_field()
				}
			},
			configChange() {
				save_config({
					data: {
						sales_config: this.config_data
					}
				}).then(res => {
					this.get_info()
				})

			},
			//删除人员选择后的数据
			manage_delete(subscript) {
				let that = this
				Modal.confirm({
					title: '温馨提示',
					content: '确定要删除该人员吗？',
					okText: '确定',
					cancelText: '取消',
					onOk() {
						let tray_send_user = []
						that.tray_send.user.forEach((item, index) => {
							if (subscript != index) {
								tray_send_user.push(item)
							}
						})
						that.tray_send.user = tray_send_user
						that.save_tray()
					}
				});

			},
			//显示人员选择弹框
			add_manage() {
				this.visible_manage = true;
			},
			//添加所选人员
			submit_manage({
				data
			}) {
				this.visible_manage = false;
				console.log(data);
				for (let i = 0; i < data.length; i++) {
					let manageData = {
						id: data[i].id,
						username: data[i].username
					}
					this.tray_send.user.push(manageData)
				}
				this.save_tray()
			},
			save_tray() {
				saveTraySend({
					data: {
						tray_send: this.tray_send
					}
				}).then(res => {
					this.get_info()
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.page_detail {
		margin: 0;
		margin-left: 5%;
	}

	.right_btn {
		margin-left: 10px;
	}

	.com_box {
		display: flex;
		margin: 5px 0;

		.com_info {
			margin-right: 20px;
		}
	}

	.info_box {
		display: flex;
		justify-content: space-between;

		.phone {
			margin-top: 50px;
			width: 300px;
			height: 541px;
			border: 10px solid #000000;
			background-size: 100% 100%;
			border-radius: 30px;
			position: relative;

			.show_box {
				border-radius: 25px;
				height: 522px;
				background: #F7F7F7;
			}
		}
	}

	.info_title {
		margin: 15px 0;
		color: rgba(0, 0, 0, 0.85)
	}

	.sign {
		margin-bottom: 10px;
	}

	.manage_main {
		margin-right: 10px;
		display: inline-block;
        margin-bottom: 20px;
		.manage_user {
			border: 1px #eeeeee solid;
			border-radius: 5px;
			padding: 5px;

			img {
				width: 16px;
				margin-top: -3px;
				cursor: pointer;
			}
		}
	}
</style>