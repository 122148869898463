<template>
    <div>
        <div class="tab_box">
			<div class="tab_title">
				<div class="tab_item">
					<div class="title_box">
						<p>项目名称</p>
						<p>是否展示</p>
					</div>
					<div class="item_box">
						<div v-for="item in project_data.slice(0,10)">
							<p>{{item.name}}</p>
							<p> <a-checkbox v-model:checked="item.value" @change='changeStatus()'></a-checkbox></p>
						</div>
					</div>
				</div>
			</div>
			<div class="tab_title" v-if="project_data.length>10">
				<div class="tab_item">
					<div class="title_box">
						<p>项目名称</p>
						<p>是否展示</p>
					</div>
					<div class="item_box">
						<div v-for="item in project_data.slice(10,project_data.length)">
							<p>{{item.name}}</p>
							<p> 
							<a-checkbox v-model:checked="item.value" @change='changeStatus()'></a-checkbox>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
    </div>
</template>

<script>
export default {
    components: {
        
    },
    props: {
        project_data: {
            type: Array,
			default: () => {
				return []
			}
        },
        type: {
            type: String,
            default:''
        }
    },
    data() {
        return {

        }
    },
    created(e) {
        
    },

    methods: {
        changeStatus() {
            this.$emit('changeStatus',this.type)
        }
    }
}
</script>

<style lang="less" scoped>
    .tab_box {
        display: flex;
        width: 400px;
        margin-top: 10px;
    }

	.tab_title {
		display: flex;
		flex: 1;
		.tab_item {
			flex: 1;
			div {
				display: flex;
				p {
					height: 40px;
					width: 150px;
					text-align: center;
					line-height: 40px;
					border: 1px solid #ccc;
				}
			}
			.title_box {
				flex-direction: row;
			}
			.item_box {
				flex-direction: column;
				display: flex;
				div {
					flex-direction: row;
					p {
						height: 40px;
						width: 150px;
						text-align: center;
						line-height: 40px;
						border: 1px solid #ccc;
					}
				}
			}
		}
	}
</style>